.hidden-control {
  opacity: 0;
  /* transition: all 300ms; */
  transition: all 300ms;
}
.control {
  opacity: 1;
  transition: all 300ms;
}

audio::-webkit-media-controls,
video::-webkit-media-controls {
  display: none !important;
}

.player-wraooer {
  position: relative;
}

.controls__div {
  direction: ltr !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  // background: rgba(0, 0, 0, 0.6);
}

.controls__icons {
  color: white;
  font-size: 50;
  transform: scale(0.9);
}

.controls__icons:hover {
  color: #fff;
  transform: scale(1);
}

.volume__slider {
  direction: ltr !important;
  width: 100;
  margin-top: -50px;
  margin-left: 130px;
}

.bottom__icons {
  color: #999;
}

.bottom__icons:hover {
  color: white;
}

.conBack {
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.6755952380952381) 64%, rgba(255, 255, 255, 0) 100%);
}
