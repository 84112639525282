.container {
    max-width: 350px !important;

    @media (min-width: 425px) {
        max-width: 450px !important;
    }
    @media (min-width: 640px) {
        max-width: 700px !important;
    }
    @media (min-width: 920px) {
        max-width: 870px !important;
    }
    @media (min-width: 1100px) {
        max-width: 1090px !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.number-wrapper {
    position: relative;
}
.custom-scrollbars {
    &::-webkit-scrollbar {
        width: 0.7rem;
        border-radius: 20px;
        margin: 1rem 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-gray;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-gray-dark;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-gray-dark;
        border-radius: 5px;
    }
}
