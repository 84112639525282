.features {
  background: rgb(0, 58, 173);
  background: linear-gradient(
    45deg,
    rgba(0, 58, 173, 1) 0%,
    rgba(41, 113, 255, 1) 100%
  );
}
.sidebarRight {
  clip-path: polygon(
    21% 0,
    100% 0,
    100% 35%,
    100% 100%,
    19% 100%,
    20% 68%,
    13% 54%,
    6% 44%,
    4% 34%,
    11% 15%
  );
}
