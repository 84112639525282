.info-card {
  display: grid;
  grid-template-columns: 2fr 2fr;

  :first-child {
    grid-row: none;
  }
  .HW {
    grid-row: 1 / span 2;
    background: rgb(230, 245, 255);
    background: linear-gradient(
      90deg,
      rgba(230, 245, 255, 1) 0%,
      rgba(199, 217, 255, 1) 100%
    );
  }
  .CLASS {
    background: rgb(255, 233, 230);
    background: linear-gradient(
      242deg,
      rgba(255, 233, 230, 1) 0%,
      rgba(255, 199, 203, 1) 100%
    );
    .bg-class {
      background-image: url("../../assets/img/class.png");
      background-repeat: no-repeat;
    }
  }
  .EXAM {
    background: rgb(255, 244, 232);
    background: linear-gradient(
      242deg,
      rgba(255, 244, 232, 1) 0%,
      rgba(249, 224, 194, 1) 100%
    );
    // padding-bottom: 0 !important;
  }
}

.date-container{
  height: fit-content;
}


.css-1hskriy{
  display: none !important;  
  }



@media only screen and (max-width: 764px){
  .info-card{
    display: flex;
    flex-direction: column;
    padding-bottom: 4.1rem;
  }
  
}