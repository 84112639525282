// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

//-----base-----------
@import "./base/font";
@import "./base/variabels";
@import "./base/base";

//------------abstracts
@import "./abstract/utility";

//------------components
@import "./features/features";
@import "./dashboard/homedash.scss";
@import "./dashboard/player.scss";
