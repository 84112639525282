@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Medium.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_UltraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Mobile";
  src: url("../../assets/fonts/IRANSansMobile_Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Web";
  src: url("../../assets/fonts/IRANSansWeb\(FaNum\).ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Web";
  src: url("../../assets/fonts/IRANSansWeb\(FaNum\)_Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Web";
  src: url("../../assets/fonts/IRANSansWeb\(FaNum\)_Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Web";
  src: url("../../assets/fonts/IRANSansWeb\(FaNum\)_Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sans-Web";
  src: url("../../assets/fonts/IRANSansWeb\(FaNum\)_UltraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
